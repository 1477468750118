var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.postData)?_c('b-card',{staticClass:"blog-edit-wrapper"},[_c('validation-observer',{ref:"blogRules",attrs:{"tag":"form"}},[_c('b-form',{staticClass:"mt-2"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex align-items-center mb-2"},[_c('h3',{staticClass:"mb-0 font-weight-normal"},[_vm._v(" Blog Post ")])])]),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Title","label-for":"blog-edit-title"}},[_c('validation-provider',{attrs:{"name":"Title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"blog-edit-title"},on:{"input":_vm.generateSlug},model:{value:(_vm.postData.title),callback:function ($$v) {_vm.$set(_vm.postData, "title", $$v)},expression:"postData.title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3691398639)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Category","label-for":"blog-edit-category"}},[_c('validation-provider',{attrs:{"name":"Category","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-tags',{attrs:{"input-id":"tags-basic","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr'},model:{value:(_vm.postData.categories),callback:function ($$v) {_vm.$set(_vm.postData, "categories", $$v)},expression:"postData.categories"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3750913431)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Program","label-for":"blog-edit-program"}},[_c('v-select',{attrs:{"id":"blog-edit-program","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.programsList,"placeholder":'Select Program'},model:{value:(_vm.postData.program),callback:function ($$v) {_vm.$set(_vm.postData, "program", $$v)},expression:"postData.program"}})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Slug","label-for":"blog-edit-slug"}},[_c('validation-provider',{attrs:{"name":"Slug","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"blog-edit-slug"},model:{value:(_vm.postData.slug),callback:function ($$v) {_vm.$set(_vm.postData, "slug", $$v)},expression:"postData.slug"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,150918420)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Visibility","label-for":"blog-edit-visibility"}},[_c('validation-provider',{attrs:{"name":"Visibility","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"blog-edit-visibility","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.visibilityOptions,"placeholder":'Select Visibility'},model:{value:(_vm.postData.visibility),callback:function ($$v) {_vm.$set(_vm.postData, "visibility", $$v)},expression:"postData.visibility"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3780521147)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Time Published","label-for":"blog-edit-date"}},[_c('validation-provider',{attrs:{"name":"Time Published","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",attrs:{"placeholder":"Time Picker","config":{ enableTime: true, dateFormat: 'Y-m-d H:i'}},model:{value:(_vm.postData.posted_at),callback:function ($$v) {_vm.$set(_vm.postData, "posted_at", $$v)},expression:"postData.posted_at"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3102097703)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Status","label-for":"blog-edit-category"}},[_c('validation-provider',{attrs:{"name":"Status","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"blog-edit-category","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.statusOptions,"placeholder":'Select Status'},model:{value:(_vm.postData.status),callback:function ($$v) {_vm.$set(_vm.postData, "status", $$v)},expression:"postData.status"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,275059295)})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Content","label-for":"blog-content"}},[_c('validation-provider',{attrs:{"name":"Content","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('quill-editor',{attrs:{"id":"blog-content"},model:{value:(_vm.postData.content),callback:function ($$v) {_vm.$set(_vm.postData, "content", $$v)},expression:"postData.content"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2279414854)})],1)],1),_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('BlogImage',{attrs:{"image-data":_vm.postData}}),(_vm.imageError && !_vm.postData.imageUploaded)?_c('small',{staticClass:"text-danger"},[_vm._v("The Image is required")]):_vm._e()],1),_c('b-col',{staticClass:"mt-50",attrs:{"cols":"12"}},[_c('b-button',{staticClass:"mr-1",attrs:{"variant":"primary"},on:{"click":_vm.handleForm}},[_vm._v(" Save ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"outline-secondary","to":{ name: 'admin-cms'}}},[_vm._v(" Cancel ")])],1)],1)],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }